import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AnyObject } from 'antd/es/_util/type';
import { Button, Form, Input, Select, ColorPicker, Flex, TreeSelect, Badge } from 'antd';
import { ParamsTools } from './params';
import { Text } from 'components/typography';
import { TreeSelectHeader } from 'components/analytics/styles';
import { useAnalytics } from 'context/analytics';
import { ACTIONS } from 'context/analytics/reducer';
import { TAnalyticsNodeTypeProperties } from 'api/types';
import { useGetAllNodeTypeProperties } from 'api/analytics/use-get-all-property-by-id';
import { useCreateBoardItem } from 'api/analytics/use-add-board-item';
import { TTreeSelectType } from './type';

const transformDataToTreeSelect = (data: TAnalyticsNodeTypeProperties): TTreeSelectType => {
  if (!data) {
    return { id: '', name: '', title: null, value: '', key: '', children: [] }; // Ensure a default structure is returned
  }

  return {
    title: (
      <TreeSelectHeader gap={16} color={data.color}>
        <Badge color={data.color} />
        {data.name}
      </TreeSelectHeader>
    ),
    value: data.id,
    key: data.id,
    disabled: true,
    children: [
      ...(data.properties || []).map((prop) => ({
        title: (
          <TreeSelectHeader gap={16} color={data.color}>
            <Badge color={data.color} />
            {prop.name}
          </TreeSelectHeader>
        ),
        disabled: false,
        name: prop.name,
        type: prop.ref_property_type_id,
        value: prop.id,
        key: prop.id,
      })),
      ...(data.edges || []).map((edge) => ({
        title: (
          <TreeSelectHeader gap={16} color={data.color}>
            <Badge color={data.color} />
            {edge.name}
          </TreeSelectHeader>
        ),
        disabled: true,
        value: edge.id,
        name: edge.name,
        key: edge.id,
        children: [
          ...(edge.properties || []).map((prop) => ({
            title: (
              <TreeSelectHeader gap={16} color={data.color}>
                <Badge color={data.color} />
                {prop.name}
              </TreeSelectHeader>
            ),
            disabled: false,
            type: prop.ref_property_type_id,
            value: prop.id,
            key: prop.id,
          })),
          edge.target && {
            title: (
              <TreeSelectHeader gap={16} color={data.color}>
                <Badge color={data.color} />
                {edge.target.name}
              </TreeSelectHeader>
            ),
            disabled: true,
            name: edge.target.name,
            key: edge.target.id,
            value: edge.target.id,
            children: (edge.target.properties || []).map((targetProp) => ({
              title: (
                <TreeSelectHeader gap={16} color={data.color}>
                  <Badge color={data.color} />
                  {targetProp.name}
                </TreeSelectHeader>
              ),
              disabled: false,
              name: targetProp.name,
              value: targetProp.id,
              type: targetProp.ref_property_type_id,
              key: targetProp.id,
            })),
          },
        ].filter(Boolean),
      })),
    ],
  };
};

export const ToolParams: React.FC = () => {
  const [chartParams, setChartParams] = useState<TTreeSelectType[]>([]);
  const { id } = useParams();
  const { nodeTypeId } = useAnalytics();
  const [form] = Form.useForm();

  const { isLoading, data: properties } = useGetAllNodeTypeProperties(nodeTypeId, { enabled: !!nodeTypeId });
  const { selectedTool, handleAction, activeBoard } = useAnalytics();

  const treeSelectData = transformDataToTreeSelect(properties as TAnalyticsNodeTypeProperties);

  const { mutate: createBoardItemFn } = useCreateBoardItem();

  const onFinish = (data: AnyObject) => {
    const xAxis_id = data.xAxis;
    const yAxis_id = data.yAxis;

    const xAxisData = chartParams.find((el) => el.id === xAxis_id);
    const yAxisData = chartParams.find((el) => el.id === yAxis_id);

    if (selectedTool?.type === 'table') {
      data.columns = data.columns.map((item: string) => ({
        key: item,
        title: item,
        dataIndex: item,
      }));
    }

    if (data['color']) {
      data['color'] = data['color'].toHexString();
    }

    const requestData = {
      project_id: id,
      title: data.title,
      name: selectedTool?.name,
      type: selectedTool?.type,
      fx: 10,
      fy: 10,
      width: 300,
      height: 300,
      operator: 'COUNT',
      color: data.color,
      params: [
        {
          property_type_id: data.xAxis,
          property_type: xAxisData?.name === 'name' ? 'name' : xAxisData?.type,
          entity_type: 'node',
          name: xAxisData?.name,
          axis: 'x_axis',
        },
        {
          property_type_id: data.yAxis,
          property_type: yAxisData?.name === 'name' ? 'name' : yAxisData?.type,
          entity_type: 'node',
          name: yAxisData?.name,
          axis: 'y_axis',
        },
      ],
    };

    createBoardItemFn(
      { id: activeBoard, data: requestData },
      {
        onSuccess: (data) => {
          handleAction({
            type: ACTIONS.ADD_TOOL_DASHBOARD,
            payload: data.data,
          });
          setChartParams([]);
        },
      }
    );

    form.resetFields();
  };

  const handleSelect = (value: string, node: TTreeSelectType) => {
    setChartParams([...chartParams, { id: value, name: node.name, type: node.type }]);
  };

  if (!selectedTool || isLoading) {
    return null;
  }

  return (
    <Flex vertical gap={24} style={{ width: '95%', marginTop: 25, padding: 10, border: '1px dashed #ccc' }}>
      <Text style={{ fontSize: 16 }}>
        Parameters ({selectedTool?.name} / {selectedTool?.type})
      </Text>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        {ParamsTools.map((tool) => {
          if (tool.type === selectedTool.name) {
            return tool.items.map((item) => (
              <Form.Item
                label={item.name}
                key={item.name}
                name={item.name}
                rules={[{ required: item.required, message: item.message }]}
              >
                {item.type === 'input' ? (
                  <Input placeholder={item.name} />
                ) : item.type === 'color' ? (
                  <ColorPicker defaultValue={item.color} />
                ) : item.type === 'select' ? (
                  <Select placeholder={item.placeholder} mode={item.mode} options={item.options} />
                ) : item.type === 'treeSelect' ? (
                  <TreeSelect
                    placeholder={item.placeholder}
                    showSearch={false}
                    treeLine
                    treeData={[treeSelectData]}
                    treeNodeLabelProp="title"
                    onSelect={handleSelect}
                  />
                ) : null}
              </Form.Item>
            ));
          }
          return null;
        })}
        <Button htmlType="submit" type="primary">
          Apply
        </Button>
      </Form>
    </Flex>
  );
};
