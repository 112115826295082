import { Drawer, Flex, Tooltip } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { ANALYTICS, COLORS } from 'helpers/constants';
import { tools } from './tools';
import { ToolParams } from './tool-params';
import { ReactComponent as LeftIcon } from 'components/icons/left-polygon.svg';
import { ReactComponent as RightIcon } from 'components/icons/right-polygon.svg';
import { Text } from 'components/typography';
import { ACTIONS } from 'context/analytics/reducer';
import { useAnalytics } from 'context/analytics';

const { TOOLBAR } = ANALYTICS;
const { MAIN_GRAY, ANALYTICS: COLORS_ANALYTICS } = COLORS;

type TToolParams = {
  name: string;
  type: string;
};

export const ToolBar: React.FC = () => {
  const { selectedTool, handleAction, toolBarWidth, barTypeWidth, boards, nodeTypeId } = useAnalytics();

  const isOpen = useMemo(() => toolBarWidth === TOOLBAR.WIDTH, [toolBarWidth]);

  const isDisableTools = useMemo(() => !boards.length || !nodeTypeId, [boards, nodeTypeId]);

  const handleToolClick = useCallback(
    (tool: TToolParams) => {
      handleAction({
        type: ACTIONS.SELECT_TOOL,
        payload: { name: tool.name, type: tool.type },
      });
    },
    [handleAction]
  );

  const handleCloseBar = useCallback(() => {
    handleAction({
      type: ACTIONS.BAR_SIZE_CHANGE,
      payload: { name: 'toolBar' },
    });
  }, [handleAction]);

  const getActiveTool = useCallback((type: string) => selectedTool?.type === type, [selectedTool]);

  return (
    <Drawer
      getContainer={false}
      title={
        <Flex align="center" gap={16} vertical={!isOpen} style={{ cursor: 'pointer' }}>
          {isOpen ? <LeftIcon onClick={handleCloseBar} /> : <RightIcon onClick={handleCloseBar} />}
          {isOpen && <Text>Visuals</Text>}
        </Flex>
      }
      headerStyle={{ height: 50 }}
      bodyStyle={{ padding: isOpen ? 24 : 5, cursor: isDisableTools ? 'not-allowed' : 'default' }}
      width={toolBarWidth}
      open={true}
      placement="right"
      closeIcon={false}
      style={{
        background: MAIN_GRAY,
        position: 'relative',
        right: barTypeWidth,
      }}
      mask={false}
      push={false}
    >
      <Flex
        gap={16}
        vertical={!isOpen}
        align="center"
        style={{
          pointerEvents: isDisableTools ? 'none' : 'auto',
          opacity: isDisableTools ? 0.5 : 1,
        }}
      >
        {tools.map((tool) => {
          const isActive = getActiveTool(tool.type);
          return (
            <Tooltip key={tool.name} title={tool.type} color={COLORS_ANALYTICS.DEFAULT_GREY}>
              <Flex
                style={{
                  cursor: 'pointer',
                  transform: isActive ? 'scale(1.4)' : 'scale(1)',
                }}
                onClick={() => handleToolClick(tool)}
              >
                {tool.icon}
              </Flex>
            </Tooltip>
          );
        })}
      </Flex>
      {isOpen && <ToolParams />}
    </Drawer>
  );
};
