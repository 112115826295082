import React, { useEffect } from 'react';
import { Layer, Stage } from 'react-konva';
import { Flex, Spin } from 'antd';
import { Boards } from 'components/analytics/boards';
import { ToolBar } from 'components/analytics/tool-bar';
import { Text } from 'components/typography';
import { Charts } from 'components/analytics/charts';
import { BarTypes } from 'components/analytics/bar-types';
import { TextEditor } from 'components/analytics/text-editor';
import { ANALYTICS, COLORS } from 'helpers/constants';
import { useAnalytics, AnalyticsProvider } from 'context/analytics';
import { ReactComponent as BuildVisualIcon } from 'components/icons/build-visual.svg';
import { useGetAllBoardItems } from 'api/analytics/use-get-all-board-item';
import { ACTIONS } from 'context/analytics/reducer';

const AnalyticsContent: React.FC = () => {
  const { CANVAS } = ANALYTICS;
  const { tools, activeTools, activeBoard, canvasWidth, handleAction } = useAnalytics();

  const renderTools = activeTools.filter((item) => item.activeBoard === activeBoard);

  const { data: allBoardItems, isLoading } = useGetAllBoardItems(activeBoard, {
    enabled: !!activeBoard,
  });

  useEffect(() => {
    if (allBoardItems?.length) {
      handleAction({
        type: ACTIONS.ADD_BOARD_ITEMS,
        payload: allBoardItems,
      });
    }
  }, [allBoardItems, handleAction]);

  return (
    <Flex>
      <Flex vertical>
        <Boards />
        <div
          style={{
            width: canvasWidth,
            height: CANVAS.MAX_HEIGHT,
            border: `2px dashed ${COLORS.MAIN_GRAY_SILVER}`,
            background: COLORS.ANALYTICS.GREY_10,
            position: 'relative',
            left: '10px',
            top: '5px',
          }}
        >
          <Stage draggable={true}>
            <Layer></Layer>
          </Stage>
          {!renderTools.length && (
            <Flex
              vertical
              style={{ width: canvasWidth, height: CANVAS.MAX_HEIGHT }}
              align="center"
              justify="center"
              gap={16}
            >
              <Spin spinning={isLoading} />
              <Text style={{ fontSize: '30px' }}>Build Visual in your data</Text>
              <BuildVisualIcon />
            </Flex>
          )}
          {renderTools.map((tool, index) => {
            if (!tool || !tool.name) {
              return null;
            }

            const boardTools = tools[activeBoard];
            if (!boardTools) {
              return null;
            }

            const key = Object.keys(boardTools)[index];
            if (!key) {
              return null;
            }

            switch (tool.name) {
              case 'chart':
                return <Charts key={key} id={key} />;
              case 'text':
                return <TextEditor key={key} id={key} />;
              default:
                return null;
            }
          })}
        </div>
      </Flex>
      <>
        <ToolBar />
        <BarTypes />
      </>
    </Flex>
  );
};

export const Analytics: React.FC = () => {
  return (
    <AnalyticsProvider>
      <AnalyticsContent />
    </AnalyticsProvider>
  );
};
