import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { GET_BOARD_ITEMS } from './constant';
import client from 'api/client';
import { TAnalyticsBoard } from 'api/types';

type TUseGetAllBoardItems = (
  id: string,
  options?: UseQueryOptions<TAnalyticsBoard[], Error>
) => {
  data: TAnalyticsBoard[] | undefined;
  isLoading: boolean;
};

export const useGetAllBoardItems: TUseGetAllBoardItems = (id, options) => {
  const result = useQuery({
    queryKey: [GET_BOARD_ITEMS, id],
    queryFn: async () => {
      const response = await client.get(GET_BOARD_ITEMS.replace(':board_id', id));
      return response.data;
    },
    ...options,
  });

  const { data, isLoading } = result;

  return {
    data,
    isLoading,
  };
};
