import React from 'react';
import { PieChart as Chart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { TChartParams, TDataItem } from './types';

const COLORS = ['#8884d8', '#82ca9d', '#FFBB28', '#FF8042', '#AF19FF'];

export const PieChart: React.FC<TChartParams> = ({ width, height, params }) => {
  return (
    <Chart width={width} height={height} key={params.id}>
      <Pie
        data={params.data}
        color={params.color}
        dataKey={`y_axis_${params.params[1].name}`}
        nameKey={`x_axis_${params.params[0].name}`}
        cx="50%"
        cy="50%"
        outerRadius={120}
      >
        {params.data.map((entry: TDataItem, index: number) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </Chart>
  );
};
