import { TAnalyticsActions, TAnalyticsState, TToolItem } from './types';
import { ANALYTICS } from 'helpers/constants';

const { TOOLBAR, BARTYPES } = ANALYTICS;

export const ACTIONS = {
  SELECT_TOOL: 'SELECT_TOOL' as const,
  CHANGE_BOARD: 'CHANGE_BOARD' as const,
  ADD_BOARD_ITEMS: 'ADD_BOARD_ITEMS' as const,
  ADD_TOOL_DASHBOARD: 'ADD_TOOL_DASHBOARD' as const,
  UPDATE_TOOL_PARAMS: 'UPDATE_TOOL_PARAMS' as const,
  BAR_SIZE_CHANGE: 'BAR_SIZE_CHANGE' as const,
  SELECT_NODE_TYPE_ID: 'SELECT_NODE_TYPE_ID' as const,
  SET_BOARDS: 'SET_BOARDS' as const,
  RENAME_BOARD_NAME: 'RENAME_BOARD_NAME' as const,
  REMOVE_BOARD: 'REMOVE_BOARD' as const,
};

export const reducer = (state: TAnalyticsState, action: TAnalyticsActions): TAnalyticsState => {
  const { type, payload } = action;

  switch (type) {
    /* 
     set all boards
     @params []
    */

    case ACTIONS.SET_BOARDS: {
      return {
        ...state,
        boards: payload,
        activeBoard: payload.at(0)?.id || '',
      };
    }

    /* 
     rename board name by id
     @params [id, name]
    */

    case ACTIONS.RENAME_BOARD_NAME: {
      return {
        ...state,
        boards: state.boards.map((board) => (board.id === payload.id ? { ...board, name: payload.name } : board)),
      };
    }

    /* 
      delete board by id
      @params [id]
    */

    case ACTIONS.REMOVE_BOARD: {
      return {
        ...state,
        boards: state.boards.filter((board) => board.id !== payload),
        activeBoard: state.activeBoard === payload ? state.boards[0].id : state.activeBoard,
      };
    }

    /*
      select node type in bar tools
      @params [id]
    */

    case ACTIONS.SELECT_NODE_TYPE_ID: {
      return {
        ...state,
        nodeTypeId: payload.id,
      };
    }

    /*
      select tool bar tool
      @params none
    */

    case ACTIONS.SELECT_TOOL:
      const uniqueKey = crypto.randomUUID();
      return {
        ...state,
        toolBarWidth: TOOLBAR.WIDTH,
        canvasWidth: state.toolBarWidth < TOOLBAR.WIDTH ? state.canvasWidth - TOOLBAR.WIDTH + 80 : state.canvasWidth,
        selectedTool: { id: uniqueKey, name: payload.name, type: payload.type },
      };

    /*
      change activeBoard on click board 
      @params [index]
    */

    case ACTIONS.CHANGE_BOARD: {
      return {
        ...state,
        activeBoard: payload,
      };
    }

    /* 
      set my board tools when open board
      @params [data]
    */

    case ACTIONS.ADD_BOARD_ITEMS: {
      return {
        ...state,
        activeTools: [
          ...state.activeTools,
          ...payload
            .filter((item) => item && item.id && item.name && item.type)
            .map((item) => ({
              id: item.id,
              name: item.name,
              type: item.type,
              activeBoard: state.activeBoard,
            })),
        ],
        tools: {
          ...state.tools,
          [state.activeBoard]: {
            ...payload.reduce((acc, item) => {
              if (item && item.id) {
                const yAxisFieldName = `y_axis_${item.params[1].name}`;
                item.data.map((item) => {
                  item[yAxisFieldName] = +item[yAxisFieldName];
                });

                acc[item.id] = {
                  x: item.fx,
                  y: item.fy,
                  width: item.width,
                  height: item.height,
                  name: item.name,
                  type: item.type,
                  color: item.color || undefined,
                  title: item.title || undefined,
                  params: item.params,
                  data: item.data,
                };
              }
              return acc;
            }, {} as Record<string, TToolItem>),
          },
        },
      };
    }

    /* 
      add dashboard tool
      @params [data: AnyObject]
    */

    case ACTIONS.ADD_TOOL_DASHBOARD: {
      if (!state.selectedTool?.id || !state.selectedTool?.type || !state.selectedTool?.name) {
        return state;
      }

      return {
        ...state,
        activeTools: [
          ...state.activeTools,
          {
            ...state.selectedTool,
            activeBoard: state.activeBoard,
          },
        ],
        tools: {
          ...state.tools,
          [state.activeBoard]: {
            ...(state.tools[state.activeBoard] || {}),
            [payload.id]: {
              x: payload.fx,
              y: payload.fy,
              width: payload.width,
              height: payload.height,
              name: payload.name,
              type: payload.type,
              color: payload.color || undefined,
              title: payload.title || undefined,
              params: payload.params,
              data: payload.data,
            },
          },
        },
        selectedTool: null,
      };
    }

    /* 
      change bar width
      @params [id,updateParams]
    */

    case ACTIONS.UPDATE_TOOL_PARAMS: {
      return {
        ...state,
        tools: {
          ...state.tools,
          [state.activeBoard]: {
            ...(state.tools[state.activeBoard] || {}),
            [payload.id]: {
              ...state.tools[state.activeBoard][payload.id],
              ...payload.updatedParams,
            },
          },
        },
      };
    }

    /* 
      change bar width
      @params [name]
    */

    case ACTIONS.BAR_SIZE_CHANGE: {
      const updatedState = { ...state };

      switch (payload.name) {
        case 'typeBar':
          updatedState.barTypeWidth = state.barTypeWidth === BARTYPES.WIDTH ? 80 : BARTYPES.WIDTH;
          break;
        case 'toolBar':
          updatedState.toolBarWidth = state.toolBarWidth === TOOLBAR.WIDTH ? 80 : TOOLBAR.WIDTH;
          break;
        default:
          break;
      }
      updatedState.canvasWidth = window.innerWidth - updatedState.barTypeWidth - updatedState.toolBarWidth - 20;
      return updatedState;
    }

    default:
      return state;
  }
};
