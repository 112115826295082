import { ReactComponent as RemoveIcon } from '../../icons/remove.svg';

export const items = [
  {
    key: 'detach',
    name: 'Detach',
    label: 'Detach',
    icon: <RemoveIcon />,
  },
];
