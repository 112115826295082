import { COLORS } from 'helpers/constants';
import { ToolParamsType } from './type';

export const ParamsTools: ToolParamsType[] = [
  {
    type: 'chart',
    items: [
      {
        type: 'input',
        name: 'title',
        required: false,
        message: 'Title is required',
      },
      {
        type: 'treeSelect',
        name: 'xAxis',
        required: false,
        message: 'xAxis is required',
        placeholder: 'Select xAxis',
        options: [],
      },
      {
        type: 'treeSelect',
        name: 'yAxis',
        required: false,
        message: 'yAxis is required',
        placeholder: 'Select yAxis',
        options: [],
      },
      {
        type: 'select',
        name: 'legend',
        required: false,
        mode: undefined,
        message: 'Legend is required',
        placeholder: 'Select legend',
        options: [
          { label: 'yes', value: true },
          { label: 'no', value: false },
        ],
      },
      {
        type: 'color',
        name: 'color',
        required: false,
        color: COLORS.PRIMARY.BLUE,
      },
    ],
  },
  {
    type: 'table',
    items: [
      {
        type: 'input',
        name: 'title',
        required: false,
        message: 'Title is required',
      },
      {
        type: 'select',
        name: 'columns',
        required: true,
        mode: 'multiple',
        options: [],
        message: 'Columns are required',
      },
    ],
  },
  {
    type: 'text',
    items: [
      {
        type: 'input',
        name: 'title',
        required: false,
        message: 'Title is required',
      },
    ],
  },
];
