import { ReactComponent as BarIcon } from 'components/icons/bar-chart.svg';
import { ReactComponent as LineIcon } from 'components/icons/line-chart.svg';
import { ReactComponent as PieIcon } from 'components/icons/pie-chart.svg';
import { ReactComponent as TableIcon } from 'components/icons/table-icon.svg';
import { ReactComponent as TextEditorIcon } from 'components/icons/text-editor.svg';

export const tools = [
  {
    name: 'chart',
    icon: <LineIcon />,
    type: 'line',
  },
  {
    name: 'chart',
    icon: <PieIcon />,
    type: 'pie',
  },
  {
    name: 'chart',
    icon: <BarIcon />,
    type: 'bar',
  },
  {
    name: 'table',
    icon: <TableIcon />,
    type: 'default',
  },
  {
    name: 'text',
    icon: <TextEditorIcon />,
    type: 'editor',
  },
];
