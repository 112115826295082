import React from 'react';
import { DraggingContainer } from '../dragging';
import { TTextEditorParams } from './type';

export const TextEditor: React.FC<TTextEditorParams> = ({ id }) => {
  return (
    <DraggingContainer containerKey={id}>
      <div style={{ width: '100%', height: '100%', border: '1px solid red' }}>asdasdasd</div>
    </DraggingContainer>
  );
};
