import React, { useState, useCallback } from 'react';
import { Flex, Menu, Popover, Typography } from 'antd';
import { ReactComponent as MenuIcon } from '../../icons/menu-icon.svg';
import { ToolHeader } from './style';
import { COLORS } from 'helpers/constants';
import type { MenuInfo } from 'rc-menu/lib/interface'; // Import MenuInfo
import { items } from './items';

type THeaderParams = {
  id: string;
  title: string;
};

export const Header: React.FC<THeaderParams> = ({ id, title }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpenChange = useCallback((open: boolean) => {
    setIsOpen(open);
  }, []);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleClickMenuItem = (e: MenuInfo) => {
    e.domEvent.stopPropagation();
    if (e.key === 'detach') {
      // Handle 'detach' case
    }
  };

  return (
    <ToolHeader justify={'space-between'} align={'center'}>
      <Flex style={{ width: '100%' }} justify="center">
        <Typography.Text style={{ fontSize: '18px' }} color={COLORS.PRIMARY.WHITE}>
          {title}
        </Typography.Text>
      </Flex>
      <Flex>
        <Popover
          content={<Menu onClick={handleClickMenuItem} items={items} />}
          trigger="click"
          open={isOpen}
          onOpenChange={handleOpenChange}
        >
          <div onMouseDown={handleClick} style={{ cursor: 'pointer' }}>
            <MenuIcon />
          </div>
        </Popover>
      </Flex>
    </ToolHeader>
  );
};
