import React, { useCallback, useEffect, useState } from 'react';
import { BoardItem, BoardsContainer } from './styled';
import { Text } from 'components/typography';
import { Input } from 'components/input';
import { ReactComponent as RemoveIcon } from 'components/icons/remove.svg';
import { COLORS } from 'helpers/constants';
import { ReactComponent as AddBoardIcon } from 'components/icons/plus-icon.svg';
import { Typography } from 'antd';
import { useAnalytics } from 'context/analytics';
import { ACTIONS } from 'context/analytics/reducer';
import { useCreateNewBoard } from 'api/analytics/use-create-new-board';
import { useGetAllBoards } from 'api/analytics/use-get-all-boards';
import { useChangeBoard } from 'api/analytics/use-change-board';
import { useDeleteBoard } from 'api/analytics/use-delete-board';

const { Paragraph } = Typography;

export const Boards: React.FC = () => {
  const { boards, activeBoard, handleAction } = useAnalytics();

  const [editingBoardIndex, setEditingBoardIndex] = useState<string | null>(null);
  const [newBoardName, setNewBoardName] = useState('');

  const { data, isLoading } = useGetAllBoards();

  const { mutate: createNewBoardFn } = useCreateNewBoard();
  const { mutate: renameBoardFn } = useChangeBoard();
  const { mutate: deleteBoardFn } = useDeleteBoard();

  const handleClickSetActiveBoard = useCallback(
    (id: string) => {
      handleAction({
        type: ACTIONS.CHANGE_BOARD,
        payload: id,
      });
    },
    [handleAction]
  );

  const handleDoubleClickBoard = useCallback((id: string, name: string) => {
    setEditingBoardIndex(id);
    setNewBoardName(name);
  }, []);

  const handleBoardNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setNewBoardName(value);
  }, []);

  const handleRenameBoard = (id: string) => {
    const name =
      newBoardName && newBoardName.length > 3
        ? newBoardName
        : boards.find((item) => item.id === editingBoardIndex)?.name || '';

    renameBoardFn(
      { id: id, name: name },
      {
        onSuccess: () => {
          handleAction({
            type: ACTIONS.RENAME_BOARD_NAME,
            payload: { id, name: name },
          });
          setEditingBoardIndex(null);
        },
      }
    );
  };

  const handleDeleteBoard = useCallback(
    (e: React.MouseEvent<SVGSVGElement>, id: string) => {
      e.stopPropagation();
      deleteBoardFn(id, {
        onSuccess: () => {
          handleAction({
            type: ACTIONS.REMOVE_BOARD,
            payload: id,
          });
        },
      });
      setEditingBoardIndex(null);
    },
    [deleteBoardFn, handleAction]
  );

  const handleClickAddBoard = () => {
    const name = `board_${boards.length}`;
    createNewBoardFn(name, {
      onSuccess: (data) => {
        handleAction({
          type: ACTIONS.SET_BOARDS,
          payload: [...boards, data.data],
        });
      },
    });
  };

  useEffect(() => {
    if (!isLoading && data) {
      handleAction({
        type: ACTIONS.SET_BOARDS,
        payload: data,
      });
    }
  }, [isLoading, data, handleAction]);

  return (
    <>
      <BoardsContainer>
        {boards.map((board, index) => (
          <BoardItem
            key={index}
            gap={20}
            justify="space-between"
            onClick={() => handleClickSetActiveBoard(board.id)}
            onDoubleClick={() => handleDoubleClickBoard(board.id, board.name)}
            style={{ borderBottom: board.id === activeBoard ? `2px solid ${COLORS.BLUE_10}` : 'none' }}
          >
            <Text>
              {editingBoardIndex === board.id ? (
                <Input
                  value={newBoardName}
                  onChange={handleBoardNameChange}
                  onPressEnter={() => handleRenameBoard(board.id)}
                  onBlur={() => handleRenameBoard(board.id)}
                  style={{ background: 'transparent' }}
                />
              ) : (
                <Paragraph
                  strong
                  ellipsis={{ rows: 1, tooltip: board.name }}
                  style={{ maxWidth: '150px', marginBottom: 0 }}
                >
                  {board.name}
                </Paragraph>
              )}
            </Text>
            <RemoveIcon onClick={(e) => handleDeleteBoard(e, board.id)} />
          </BoardItem>
        ))}
        {boards.length < 5 && (
          <BoardItem
            style={{
              cursor: 'pointer',
              width: 'max-content',
            }}
            justify="center"
            onClick={() => handleClickAddBoard()}
          >
            <AddBoardIcon />
          </BoardItem>
        )}
      </BoardsContainer>
    </>
  );
};
