import { Badge, Flex } from 'antd';
import styled from 'styled-components';

export const CustomBadge = styled(Badge)`
  & .ant-badge-status-dot {
    width: 20px !important;
    height: 20px !important;
  }
`;

export const TreeSelectHeader = styled(Flex)<{ color: string }>`
  &:hover {
    background-color: ${(props) => `${props.color}20`};
  }
`;
