import React from 'react';
import { Bar, BarChart as Chart, CartesianGrid, Legend, XAxis, YAxis } from 'recharts';
import { Tooltip } from 'antd';
import { TChartParams } from './types';

export const BarChart: React.FC<TChartParams> = ({ width, height, params }) => {
  return (
    <Chart width={width} height={height} data={params.data} key={params.id}>
      <CartesianGrid strokeDasharray="3 3" />
      <YAxis dataKey={`y_axis_${params.params[1].name}`} />
      <XAxis dataKey={`x_axis_${params.params[0].name}`} />
      <Tooltip />
      <Legend />
      <Bar dataKey={`x_axis_${params.params[0].name}`} fill={params.color} />
      <Bar dataKey={`y_axis_${params.params[1].name}`} fill={params.color} />
    </Chart>
  );
};
