import React, { useCallback, useEffect, useRef, useState } from 'react';
import { COLORS, ANALYTICS } from 'helpers/constants';
import { Flex } from 'antd';
import { ResizableBox } from 'react-resizable';
import { useAnalytics } from 'context/analytics';
import { ACTIONS } from 'context/analytics/reducer';
import 'react-resizable/css/styles.css';
import { Header } from '../header';

const { CANVAS } = ANALYTICS;
const { MAIN_GRAY } = COLORS;

type TDraggingParams = {
  containerKey: string;
  children: React.ReactNode | null;
};

export const DraggingContainer: React.FC<TDraggingParams> = ({ containerKey, children }) => {
  const { tools, activeBoard, handleAction, canvasWidth } = useAnalytics();
  const selectedTools = tools[activeBoard][containerKey];

  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({
    x: selectedTools.x,
    y: selectedTools.y,
    offsetX: 0,
    offsetY: 0,
  });

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        let newX = e.clientX - position.offsetX;
        let newY = e.clientY - position.offsetY;

        if (newX < 0) newX = 0;
        if (newY < 0) newY = 0;
        if (newX + selectedTools.width > canvasWidth) newX = canvasWidth - selectedTools.width - 10;
        if (newY + selectedTools.height + 45 > CANVAS.MAX_HEIGHT) newY = CANVAS.MAX_HEIGHT - selectedTools.height - 55;

        setPosition((prevPos) => ({
          ...prevPos,
          x: newX,
          y: newY,
        }));
      }
    },
    [isDragging, position.offsetX, position.offsetY, selectedTools.width, selectedTools.height, canvasWidth]
  );

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    if (!target.classList.contains('react-resizable-handle')) {
      setIsDragging(true);
      setPosition((prev) => ({
        ...prev,
        offsetX: e.clientX - prev.x,
        offsetY: e.clientY - prev.y,
      }));
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);
      handleAction({
        type: ACTIONS.UPDATE_TOOL_PARAMS,
        payload: {
          id: containerKey,
          updatedParams: { x: position.x, y: position.y },
        },
      });
    }
  }, [isDragging, handleAction, containerKey, position.x, position.y]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    if (position.x + selectedTools.width > canvasWidth) {
      setPosition((prevPos) => ({
        ...prevPos,
        x: canvasWidth - selectedTools.width - 10,
      }));
    }
  }, [canvasWidth, selectedTools.width, position.x]);

  return (
    <Flex
      ref={containerRef}
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
        zIndex: 1,
        border: `1px dashed ${MAIN_GRAY}`,
      }}
      vertical
      onMouseDown={handleMouseDown}
    >
      <Header title={selectedTools.title || ''} id={containerKey} />
      <ResizableBox
        width={selectedTools.width || 300}
        height={selectedTools.height || 300}
        minConstraints={[100, 100]}
        maxConstraints={[canvasWidth, CANVAS.MAX_HEIGHT]}
        onResizeStop={(e, { size }) => {
          handleAction({
            type: ACTIONS.UPDATE_TOOL_PARAMS,
            payload: {
              id: containerKey,
              updatedParams: { width: size.width, height: size.height - 40 },
            },
          });
        }}
        resizeHandles={['se']}
      >
        <div style={{ width: '100%', height: '100%' }}>{children}</div>
      </ResizableBox>
    </Flex>
  );
};
