import styled from 'styled-components';
import { Flex } from 'antd';
import { ANALYTICS, COLORS } from 'helpers/constants';

export const BoardsContainer = styled(Flex)`
  & {
    width: calc(100% - ${ANALYTICS.CANVAS.MAX_WIDTH});
    border-bottom: 2px solid #d9d9d9;
    height: 50px;
    padding: 8px;
    background: ${COLORS.MAIN_GRAY};
  }
`;

export const BoardItem = styled(Flex)`
  & {
    cursor: pointer;
    width: 20%;
    height: 42px;
    border-right: 2px solid #d9d9d9;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    padding: 0 16px;
  }
`;
