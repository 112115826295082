import React from 'react';
import { DraggingContainer } from '../dragging';
import { TChart } from './types';
import { useAnalytics } from 'context/analytics';
import { LineChart } from './line-chart';
import { BarChart } from './bar-chart';
import { PieChart } from './pie-chart';

export const Charts: React.FC<TChart> = ({ id }) => {
  const { tools, activeBoard } = useAnalytics();

  const boardTools = tools[activeBoard];

  const getContentChart = (): React.ReactNode => {
    switch (boardTools[id].type) {
      case 'line':
        return <LineChart width={boardTools[id].width} height={boardTools[id].height} params={boardTools[id]} />;
      case 'bar':
        return <BarChart width={boardTools[id].width} height={boardTools[id].height} params={boardTools[id]} />;
      case 'pie':
        return <PieChart width={boardTools[id].width} height={boardTools[id].height} params={boardTools[id]} />;
      default:
        return null;
    }
  };

  return <DraggingContainer containerKey={id}>{getContentChart() || null}</DraggingContainer>;
};
