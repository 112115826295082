import React from 'react';

import { LineChart as Chart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { TChartParams } from './types';

export const LineChart: React.FC<TChartParams> = ({ width, height, params }) => {
  return (
    <Chart
      width={width}
      height={height}
      data={params.data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
      key={params.id}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <YAxis dataKey={`y_axis_${params.params[1].name}`} />
      <XAxis dataKey={`x_axis_${params.params[0].name}`} />
      <Tooltip />
      {params?.legend && <Legend />}
      <Line type="monotone" dataKey={`y_axis_${params.params[1].name}`} stroke={params.color} activeDot={{ r: 8 }} />
    </Chart>
  );
};
